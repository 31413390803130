import { RegionDTO } from "types/generic"

export const REGION: RegionDTO[] = [
  {
    id: 192,
    name: "Abruzzo",
    shortName: null,
    countryId: 1,
  },
  {
    id: 193,
    name: "Basilicata",
    shortName: null,
    countryId: 1,
  },
  {
    id: 194,
    name: "Calabria",
    shortName: null,
    countryId: 1,
  },
  {
    id: 195,
    name: "Campania",
    shortName: null,
    countryId: 1,
  },
  {
    id: 196,
    name: "Emilia-Romagna",
    shortName: null,
    countryId: 1,
  },
  {
    id: 197,
    name: "Friuli-Venezia Giulia",
    shortName: null,
    countryId: 1,
  },
  {
    id: 198,
    name: "Lazio",
    shortName: null,
    countryId: 1,
  },
  {
    id: 199,
    name: "Liguria",
    shortName: null,
    countryId: 1,
  },
  {
    id: 200,
    name: "Lombardia",
    shortName: null,
    countryId: 1,
  },
  {
    id: 201,
    name: "Marche",
    shortName: null,
    countryId: 1,
  },
  {
    id: 202,
    name: "Molise",
    shortName: null,
    countryId: 1,
  },
  {
    id: 203,
    name: "Piemonte",
    shortName: null,
    countryId: 1,
  },
  {
    id: 204,
    name: "Puglia",
    shortName: null,
    countryId: 1,
  },
  {
    id: 205,
    name: "Sardegna",
    shortName: null,
    countryId: 1,
  },
  {
    id: 206,
    name: "Sicilia",
    shortName: null,
    countryId: 1,
  },
  {
    id: 207,
    name: "Toscana",
    shortName: null,
    countryId: 1,
  },
  {
    id: 208,
    name: "Trentino-Alto Adige",
    shortName: null,
    countryId: 1,
  },
  {
    id: 209,
    name: "Umbria",
    shortName: null,
    countryId: 1,
  },
  {
    id: 210,
    name: "Valle d'Aosta",
    shortName: null,
    countryId: 1,
  },
  {
    id: 211,
    name: "Veneto",
    shortName: null,
    countryId: 1,
  },
]
