import React, { useEffect, useMemo, useState } from "react"
import "./style.scss"
import AllPageCard from "components/AllPage/Card"
import { getButtonByType, getColorsByType, getInfosByType } from "components/AllPage/utility"
import Loading from "components/Loading"
import InputWithLabel from "components/InputWithLabel"
import GenericInputKama from "components/inputs/GenericInput"
import { AllPageButtonType, AllPageType } from "types/allPage"
import { RegistrationInfo } from "types/registration"
import DatePickerKama from "components/inputs/DatePicker"
import { Dayjs } from "dayjs"
import MobileInputKama from "components/inputs/MobileInput"
import InputNumberKama from "components/inputs/InputNumber"
import SelectKama, { SelectOptionProps } from "components/inputs/Select"
import { NavigationElementProps } from "components/NavigationBar/element"
import {
  PAGES,
  KL_AMERICAS_DRAFT_ID,
  KL_AMERICAS_PAGES,
  QL_AMERICAS_DRAFT_ID,
  QL_AMERICAS_PAGES,
} from "utility/constants"
import NavigationBar from "components/NavigationBar"
import { Col, Row } from "antd"
import RadioButtonKama from "components/inputs/RadioButton"
import AddAttachmentKama from "components/inputs/AddAttachment"
import { UploadFile } from "antd/es/upload/interface"
import { DraftPlayerAPI } from "apis/draftPlayers"
import SuccessRegistrationCard from "components/SuccessRegistrationCard"
import ErrorRegistrationCard from "components/ErrorRegistrationCard"
import { COUNTRIES } from "utility/countries"
import { CountryDTO } from "types/generic"
import { REGION } from "utility/region"
import { useTranslation } from "react-i18next"

type RegistrationCardProps = {
  type: AllPageType
}

type RegistrationCardColor = {
  label: string | null
  mandatoryLabel: string | null
}

const REGISTRATION_INFO_OPTIONS: {
  key: keyof RegistrationInfo
  suffixKey?: keyof RegistrationInfo
  Input: (props: any) => JSX.Element
  validation?: "email" | "pastDate" | "futureDate" | "boolean" | "link"
  label: string
  placeholder?: string
  suffix?: string
  options?: string
  allowTypes?: string[]
  maxNumber?: number
  colSize?: number
  colSizeLG?: number
  parentKey?: keyof RegistrationInfo
  parentKeyValue?: number
  confirmKey?: keyof RegistrationInfo
  externalLink?: string
  bigLabel?: boolean
}[] = [
  {
    key: "firstName",
    Input: GenericInputKama,
    label: "FIRSTNAME_LABEL",
    placeholder: "FIRSTNAME_PLACEHOLDER",
  },
  {
    key: "lastName",
    Input: GenericInputKama,
    label: "LASTNAME_LABEL",
    placeholder: "LASTNAME_PLACEHOLDER",
  },
  {
    key: "email",
    Input: GenericInputKama,
    validation: "email",
    label: "EMAIL_LABEL",
    placeholder: "EMAIL_PLACEHOLDER",
  },
  {
    key: "emailConfirmation",
    Input: GenericInputKama,
    validation: "email",
    confirmKey: "email",
    label: "EMAIL_CONFIRMATION_LABEL",
    placeholder: "EMAIL_CONFIRMATION_PLACEHOLDER",
  },
  {
    key: "playerImage",
    Input: AddAttachmentKama,
    label: "PLAYER_IMAGE_LABEL",
    placeholder: "PLAYER_IMAGE_PLACEHOLDER",
    allowTypes: ["image"],
  },
  {
    key: "telephone",
    suffixKey: "telephonePrefix",
    Input: MobileInputKama,
    validation: undefined,
    options: "countries",
    label: "MOBILE_NUMBER_LABEL",
    placeholder: "MOBILE_NUMBER_PLACEHOLDER",
  },
  {
    key: "birthDate",
    Input: DatePickerKama,
    label: "BIRTH_DATE_LABEL",
    placeholder: "BIRTH_DATE_PLACEHOLDER",
    validation: "pastDate",
  },
  {
    key: "gender",
    Input: RadioButtonKama,
    label: "GENDER_LABEL",
    options: "genders",
  },
  {
    key: "countryId",
    options: "countrOptions",
    Input: SelectKama,
    label: "COUNTRY_LABEL",
    placeholder: "COUNTRY_PLACEHOLDER",
  },
  {
    key: "secondaryCountryId",
    options: "countrOptions",
    Input: SelectKama,
    label: "SECONDARY_COUNTRY_LABEL",
    placeholder: "SECONDARY_COUNTRY_PLACEHOLDER",
  },
  {
    key: "countryOfResidenceId",
    options: "countrOptions",
    Input: SelectKama,
    label: "RESIDENCE_COUNTRY_LABEL",
    placeholder: "RESIDENCE_COUNTRY_PLACEHOLDER",
  },
  {
    key: "regionOfResidenceId",
    options: "regionOptions",
    parentKey: "countryOfResidenceId",
    parentKeyValue: 1,
    Input: SelectKama,
    label: "RESIDENCE_REGION_LABEL",
    placeholder: "RESIDENCE_REGION_PLACEHOLDER",
  },
  {
    key: "city",
    Input: GenericInputKama,
    label: "CITY_LABEL",
    placeholder: "CITY_PLACEHOLDER",
  },
  {
    key: "address",
    Input: GenericInputKama,
    label: "ADDRESS_LABEL",
    placeholder: "ADDRESS_PLACEHOLDER",
  },
  {
    key: "postalCode",
    Input: GenericInputKama,
    label: "POSTAL_CODE_LABEL",
    placeholder: "POSTAL_CODE_PLACEHOLDER",
  },
  {
    key: "emptyValue",
    parentKey: "emptyValue",
    label: "EMPTY_VALUE_LABEL",
    Input: () => <div />,
  },
  {
    key: "height",
    Input: InputNumberKama,
    label: "HEIGHT_LABEL",
    placeholder: "HEIGHT_PLACEHOLDER",
    suffix: "cm",
  },
  {
    key: "weight",
    Input: InputNumberKama,
    label: "WEIGHT_LABEL",
    placeholder: "WEIGHT_PLACEHOLDER",
    suffix: "kg",
  },
  {
    key: "hasPassportId",
    Input: RadioButtonKama,
    label: "PASSPORT_LABEL",
    options: "yesOrNo",
    colSize: 24,
    //validation: "boolean",
  },
  {
    key: "passportId",
    Input: GenericInputKama,
    label: "PASSPORT_NUMBER_LABEL",
    placeholder: "PASSPORT_PLACEHOLDER",
    colSize: 8,
    parentKey: "hasPassportId",
  },
  {
    key: "passportExpirationDate",
    Input: DatePickerKama,
    label: "PASSPORT_EXPIRING_DATE_LABEL",
    validation: "futureDate",
    colSize: 8,
    parentKey: "hasPassportId",
  },
  {
    key: "passportFiles",
    Input: AddAttachmentKama,
    label: "PASSPORT_FILES_LABEL",
    placeholder: "PASSPORT_FILES_PLACEHOLDER",
    maxNumber: 2,
    colSize: 8,
    parentKey: "hasPassportId",
  },
  {
    key: "hasNationalId",
    Input: RadioButtonKama,
    label: "NATIONAL_ID_LABEL",
    options: "yesOrNo",
    //validation: "boolean",
    colSize: 24,
  },
  {
    key: "nationalIdNumber",
    Input: GenericInputKama,
    label: "NATIONAL_ID_NUMBER_LABEL",
    placeholder: "NATIONAL_ID_PLACEHOLDER",
    colSize: 8,
    parentKey: "hasNationalId",
  },
  {
    key: "nationalIdExpirationDate",
    Input: DatePickerKama,
    label: "NATIONAL_ID_EXPIRING_DATE_LABEL",
    colSize: 8,
    validation: "futureDate",
    parentKey: "hasNationalId",
  },
  {
    key: "nationalIdFiles",
    Input: AddAttachmentKama,
    label: "NATIONAL_ID_FILES_LABEL",
    placeholder: "NATIONAL_ID_FILES_PLACEHOLDER",
    maxNumber: 2,
    colSize: 8,
    parentKey: "hasNationalId",
  },
  {
    key: "hasPlayedInYouthTeam",
    Input: RadioButtonKama,
    label: "HAS_PLAYED_IN_YOUTH_TEAM_LABEL",
    options: "yesOrNo",
    colSizeLG: 8,
  },
  {
    key: "youthTeam",
    Input: GenericInputKama,
    label: "YOUTH_TEAM_LABEL",
    placeholder: "YOUTH_TEAM_PLACEHOLDER",
    colSize: 12,
    colSizeLG: 10,
    parentKey: "hasPlayedInYouthTeam",
  },
  {
    key: "yearsSpentInYouthTeams",
    Input: InputNumberKama,
    label: "YOUTH_TEAM_YEARS_LABEL",
    placeholder: "YOUTH_TEAM_YEARS_PLACEHOLDER",
    colSizeLG: 6,
    parentKey: "hasPlayedInYouthTeam",
  },
  {
    key: "youthTeamOptions",
    Input: RadioButtonKama,
    label: "YOUTH_TEAM_OPTIONS_LABEL",
    options: "youthTeamOptions",
    parentKey: "hasPlayedInYouthTeam",
    colSizeLG: 24,
  },
  {
    key: "hasPlayedInProfessionalTeam",
    Input: RadioButtonKama,
    label: "HAS_PLAYED_IN_PROFESSIONAL_TEAM_LABEL",
    options: "yesOrNo",
    colSizeLG: 8,
  },
  {
    key: "professionalTeam",
    Input: GenericInputKama,
    label: "PROFESSIONAL_TEAM_LABEL",
    placeholder: "PROFESSIONAL_TEAM_PLACEHOLDER",
    colSize: 12,
    colSizeLG: 10,
    parentKey: "hasPlayedInProfessionalTeam",
  },
  {
    key: "yearsSpentInProfessionalTeams",
    Input: InputNumberKama,
    label: "PROFESSIONAL_TEAM_YEARS_LABEL",
    placeholder: "PROFESSIONAL_TEAM_YEARS_PLACEHOLDER",
    colSizeLG: 6,
    parentKey: "hasPlayedInProfessionalTeam",
  },
  {
    key: "videoLink",
    Input: GenericInputKama,
    label: "VIDEO_LINK_LABEL",
    validation: "link",
    placeholder: "VIDEO_LINK_PLACEHOLDER",
    //parentKey: "hasVideoLink",
  },
  {
    key: "displayName",
    Input: GenericInputKama,
    label: "DISPLAY_NAME_LABEL",
    placeholder: "DISPLAY_NAME_PLACEHOLDER",
  },
  {
    key: "role",
    Input: RadioButtonKama,
    label: "ROLE_LABEL",
    options: "roles",
  },
  {
    key: "foot",
    Input: RadioButtonKama,
    label: "FOOT_LABEL",
    options: "foots",
  },
  {
    key: "tshirtSize",
    Input: RadioButtonKama,
    label: "TSHIRT_SIZE_LABEL",
    options: "sizes",
  },
  {
    key: "shortSize",
    Input: RadioButtonKama,
    label: "SHORT_SIZE_LABEL",
    options: "sizes",
  },
  {
    key: "isConditionAccepted",
    Input: RadioButtonKama,
    label: "IS_CONDITION_ACCEPTED_LABEL",
    options: "yes",
    externalLink: "https://s3.eu-central-2.wasabisys.com/kingsleague-cdn.kama.football/draft/TC_KL_Italy.pdf",
    bigLabel: true,
  },
  {
    key: "isPrivacyPolicyAccepted",
    Input: RadioButtonKama,
    label: "IS_PRIVACY_POLICY_ACCEPTED_LABEL",
    options: "yes",
    bigLabel: true,
  },
  {
    key: "isAdult",
    Input: RadioButtonKama,
    label: "IS_ADULT_LABEL",
    options: "yes",
    bigLabel: true,
  },
  {
    key: "canTrainingBefore",
    Input: RadioButtonKama,
    label: "CAN_TRAINING_BEFORE_LABEL",
    options: "yesOrNo",
    bigLabel: true,
  },
  {
    key: "isAvailableToPlay",
    Input: RadioButtonKama,
    label: "AVAILABLE_TO_PLAY_LABEL",
    options: "yesOrNo",
    bigLabel: true,
  },
  {
    key: "hasAccessToCountry",
    Input: RadioButtonKama,
    label: "HAS_ACCESS_TO_COUNTRY_LABEL",
    options: "yesOrNo",
    bigLabel: true,
  },
  {
    key: "hasMedicalCertificate",
    Input: RadioButtonKama,
    label: "HAS_MEDICAL_CERTICATE_LABEL",
    options: "yesOrNo",
  },
  {
    key: "medicalCertificateFiles",
    Input: AddAttachmentKama,
    label: "MEDICAL_CERTIFICATE_FILES_LABEL",
    placeholder: "MEDICAL_CERTIFICATE_FILES_PLACEHOLDER",
    maxNumber: 2,
    parentKey: "hasMedicalCertificate",
  },
  {
    key: "hasSocialSecurityNumber",
    Input: RadioButtonKama,
    label: "HAS_SOCIAL_SECURITY_LABEL",
    options: "yesOrNo",
  },
  {
    key: "socialSecurityNumber",
    Input: GenericInputKama,
    label: "SOCIAL_SECURITY_NUMBER_LABEL",
    placeholder: "SOCIAL_SECURITY_NUMBER_PLACEHOLDER",
    //parentKey: "hasSocialSecurityNumber",
  },
  {
    key: "hasWorkingPermit",
    Input: RadioButtonKama,
    label: "HAS_WORKING_PERMIT_LABEL",
    options: "yesOrNo",
  },
  {
    key: "hasResidencePermit",
    Input: RadioButtonKama,
    label: "HAS_RESIDENCE_PERMIT_LABEL",
    options: "yesOrNo",
  },
]

const NOT_ALLOWED_SPACE_KEYS = ["email", "emailConfirmation"]

export default function RegistrationCard(props: RegistrationCardProps) {
  const { type } = props

  const [color, setColor] = useState<RegistrationCardColor>()
  const [buttonType, setButtonType] = useState<AllPageButtonType>()
  const [formInfoText, setFormInfoText] = useState<string>()
  const [info, setInfo] = useState<(keyof RegistrationInfo)[]>([])
  const [mandatoryInfo, setMandatoryInfo] = useState<(keyof RegistrationInfo)[]>([])
  const [values, setValues] = useState<RegistrationInfo>({})
  const [draftId, setDraftId] = useState<number>()
  const [step, setStep] = useState<number>(1)
  const [steps, setSteps] = useState<NavigationElementProps[]>([])
  const [labelsAssociations, setLabelsAssociations] = useState<{ [key in keyof RegistrationInfo]: string }>({})
  const [parentsAssociations, setParentsAssociations] = useState<{ [key in keyof RegistrationInfo]: string }>({})
  const [options, setOptions] = useState<{
    countrOptions: SelectOptionProps[]
    regionOptions: SelectOptionProps[]
    countries: CountryDTO[]
    yesOrNo: { id: number; name: string }[]
    yes: { id: number; name: string }[]
    genders: { id: string; name: string }[]
    foots: { id: string; name: string }[]
    roles: { id: string; name: string }[]
    sizes: { id: string; name: string }[]
    youthTeamOptions: { id: string; name: string }[]
  }>({
    countrOptions: COUNTRIES?.map((country) => {
      return {
        id: country.id,
        name: country.name,
        image: country.image
          ? {
              id: country.id,
              path: country.image,
            }
          : undefined,
      }
    }),
    regionOptions: REGION?.map((region) => {
      return {
        id: region.id,
        name: region.name + (region.shortName ? ` (${region.shortName})` : ""),
      }
    }),
    countries: COUNTRIES,
    yesOrNo: [
      { id: 1, name: "Sì" },
      { id: -1, name: "No" },
    ],
    yes: [{ id: 1, name: "Sì" }],
    genders: [
      { id: "male", name: "MALE" },
      { id: "female", name: "FEMALE" },
    ],
    foots: [
      { id: "left", name: "LEFT" },
      { id: "right", name: "RIGHT" },
    ],
    roles: [
      { id: "goalkeeper", name: "GOALKEEPER" },
      { id: "defender", name: "DEFENDER" },
      { id: "midfielder", name: "MIDFIELDER" },
      { id: "forward", name: "FORWARD" },
    ],
    sizes: [
      { id: "s", name: "S" },
      { id: "m", name: "M" },
      { id: "l", name: "L" },
      { id: "xl", name: "XL" },
    ],
    youthTeamOptions: [
      { id: "primavera1", name: "Primavera 1" },
      { id: "primavera2", name: "Primavera 2" },
      { id: "under18", name: "Under 18" },
      { id: "other", name: "Nessuna delle precedenti" },
    ],
  })

  /*const getCountries = async () => {
    try {
      const countryResponse = await CountryAPI.getAll()
      setOptions({ ...options, countries: countryResponse.data })
    } catch (error) {
      OpenNotificationWithMessage({ type: "warning" })
    }
  }*/

  useEffect(() => {
    //getCountries()
    const labelsAssociations: { [key in keyof RegistrationInfo]: string } = {}
    const parentsAssociations: { [key in keyof RegistrationInfo]: string } = {}
    REGISTRATION_INFO_OPTIONS.forEach((input) => {
      labelsAssociations[input.key] = input.label
      if (input.parentKey) parentsAssociations[input.key] = input.parentKey
    })
    setLabelsAssociations(labelsAssociations)
    setParentsAssociations(parentsAssociations)
  }, [])

  useEffect(() => {
    const color = getColorsByType(type)
    const buttonType = getButtonByType(type)
    setButtonType(buttonType)
    setColor({
      label: color.label,
      mandatoryLabel: color.mandatoryLabel,
    })
    const { info, mandatory } = getInfosByType(type, step, values)
    setInfo(info)
    setMandatoryInfo(mandatory)
    switch (type) {
      case "kings-league-america":
        setDraftId(KL_AMERICAS_DRAFT_ID)
        setSteps(KL_AMERICAS_PAGES)
        //setFormInfoText("KINGS_LEAGUE_WC_FORM_INFO_TEXT")
        break
      case "queens-league-america":
        setDraftId(QL_AMERICAS_DRAFT_ID)
        setSteps(QL_AMERICAS_PAGES)
        break
      case "kama":
        setSteps(PAGES)
        break
      default:
        break
    }
  }, [type])

  const [loading, setLoading] = useState<boolean>()
  const [alerts, setAlerts] = useState<{ [key in keyof RegistrationInfo]: string }>({})
  const [disabledNext, setDisabledNext] = useState<boolean>(true)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [missingFields, setMissingFields] = useState<string[]>([])

  const { t } = useTranslation()

  useEffect(() => {
    if (step && steps?.length > 1) {
      setSteps(
        steps.map((element) => {
          if (element.step < step) {
            return {
              ...element,
              status: "complete",
            }
          } else if (element.step === step) {
            return {
              ...element,
              status: "active",
            }
          }
          return {
            ...element,
            status: "disabled",
          }
        }),
      )
      const { info, mandatory } = getInfosByType(type, step, values)
      const keys = Object.keys(alerts)
      const newValues = { ...values }
      keys.forEach((key) => {
        delete newValues[key as keyof RegistrationInfo]
      })
      setInfo(info)
      setMandatoryInfo(mandatory)
      setAlerts({})
      setValues(newValues)
    }
  }, [step])

  const onChange = (
    key: keyof RegistrationInfo,
    value: string | number | Dayjs | UploadFile[] | null,
    suffixKey?: string,
    suffixValue?: string,
  ) => {
    if (NOT_ALLOWED_SPACE_KEYS.includes(key)) {
      value = (value as string).trim()
    }
    const newValues = { ...values, [key]: value }
    if (suffixKey) {
      newValues[suffixKey] = suffixValue
    }
    const childKeys = REGISTRATION_INFO_OPTIONS.filter((input) => input.parentKey === key)
    if (childKeys?.length && value === -1) {
      childKeys.forEach((childKey) => {
        newValues[childKey.key] = undefined
      })
    }
    setValues(newValues)
  }

  useEffect(() => {
    const keys: (keyof RegistrationInfo)[] = Object.keys(values) as (keyof RegistrationInfo)[]
    let newAlerts = { ...alerts }
    keys.forEach((key) => {
      const info = REGISTRATION_INFO_OPTIONS.find((input) => input.key === key)
      const validation = info?.validation
      const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/
      const linkRegex: RegExp = /^(http|https):\/\/[^ "]+$/
      let emailFormat = false
      const value = values[key]
      if (validation) {
        switch (validation) {
          case "boolean":
            if ((value as number) === -1 && mandatoryInfo.includes(key)) {
              newAlerts[key] = "FIELD_REQUIRED"
            } else {
              delete newAlerts[key]
            }
            break
          case "email":
            emailFormat = value ? emailRegex.test(value as string) : true
            if (!emailFormat) {
              newAlerts[key] = "EMAIL_FORMAT_NOT_CORRECT"
            } else {
              delete newAlerts[key]
            }
            break
          case "link":
            emailFormat = value ? linkRegex.test(value as string) : true
            if (!emailFormat) {
              newAlerts[key] = "LINK_FORMAT_NOT_CORRECT"
            } else {
              delete newAlerts[key]
            }
            break
          case "pastDate":
            if (value && (value as Dayjs).isAfter(new Date())) {
              newAlerts[key] = "DATE_NOT_CORRECT"
            } else {
              delete newAlerts[key]
            }
            break
          case "futureDate":
            if (value && (value as Dayjs).isBefore(new Date())) {
              newAlerts[key] = "DATE_NOT_CORRECT"
            } else {
              delete newAlerts[key]
            }
            break
        }
      }
      if (!newAlerts[key] && info?.confirmKey && value) {
        if (values[info.confirmKey] !== value) {
          newAlerts[key] = info.confirmKey.toUpperCase() + "_MUST_BE_EQUAL"
        } else {
          delete newAlerts[key]
        }
      }
    })
    setAlerts(newAlerts)
  }, [values])

  useEffect(() => {
    let disabledNext = false
    let missingFields: string[] = []
    mandatoryInfo?.forEach((key) => {
      if (!values[key]) {
        const parentKey = parentsAssociations[key]
        if (!parentKey || !values[parentKey] || values[parentKey] !== -1) {
          const label = labelsAssociations[key]
          if (label) {
            missingFields.push(label)
          }
          disabledNext = true
        }
      }
    })
    const keys = Object.keys(alerts)
    if (keys?.length > 0) {
      disabledNext = true
      keys.forEach((key) => {
        const label = labelsAssociations[key as keyof RegistrationInfo]
        if (label) {
          missingFields.push(label)
        }
      })
    }
    setDisabledNext(disabledNext)
    setMissingFields(missingFields)
  }, [values, alerts, mandatoryInfo])

  const confirm = async (e: React.MouseEvent, token: string) => {
    e.preventDefault()
    setLoading(true)
    setError(false)
    try {
      await DraftPlayerAPI.create(values, token, draftId)
      setSuccess(true)
    } catch (error: any) {
      console.log(error)
      setError(true)
    }
    setLoading(false)
  }

  if (!color || !buttonType) return null

  return (
    <div>
      {formInfoText ? (
        <AllPageCard type={type}>
          <div className="registrationCardInfoText">
            <span>{t(formInfoText)}</span>
            <a
              href={
                "https://s3.eu-central-2.wasabisys.com/kingsleague-cdn.kama.football/draft/24_KWC_Players_Terms_and_Condicionts_Tur.pdf"
              }
              target="_blank"
            >
              <span className="link">link</span>
            </a>
          </div>
        </AllPageCard>
      ) : null}
      <AllPageCard type={type}>
        <div className="registrationCard">
          {loading ? (
            <Loading
              open={loading}
              description={"SENDING_DESCRIPTION_DESCRIPTION"}
              secondDescription={"SENDING_DESCRIPTION_DESCRIPTION_TWO"}
              type={type}
            />
          ) : null}
          {success ? <SuccessRegistrationCard type={type} /> : null}
          {error ? <ErrorRegistrationCard type={type} confirm={() => setError(false)} /> : null}
          {!success && !error ? (
            <NavigationBar
              steps={steps}
              type={type}
              currentStep={step}
              onClick={(e, step) => {
                setStep(step)
              }}
              disabledNext={disabledNext}
              missingFields={missingFields}
              confirm={confirm}
            >
              <Row className="registrationCardDiv">
                {REGISTRATION_INFO_OPTIONS?.map((input) => {
                  if (!info.includes(input.key)) return null
                  if (
                    input.parentKey &&
                    (!values[input.parentKey] ||
                      values[input.parentKey] === -1 ||
                      (input.parentKeyValue && input.parentKeyValue !== values[input.parentKey]))
                  )
                    return <Col xs={24} md={input.colSize || 12} lg={input.colSizeLG || 12} key={input.key} />
                  return (
                    <Col
                      xs={24}
                      md={input.colSize || 12}
                      lg={input.colSizeLG || 12}
                      className="registrationCardInfoDiv"
                      key={input.key}
                    >
                      <InputWithLabel
                        label={input.label}
                        mandatory={mandatoryInfo.includes(input.key)}
                        labelColor={color.label || undefined}
                        mandatoryLabelColor={color.mandatoryLabel || undefined}
                        externalLink={input.externalLink}
                        small={true}
                        bigLabel={input.bigLabel}
                        Input={
                          <input.Input
                            allowClear={true}
                            showSearch={true}
                            placeholder={input.placeholder}
                            onChange={(e: string | Dayjs | number | UploadFile[] | null, suffix?: string) => {
                              return onChange(input.key, e, input.suffixKey, suffix)
                            }}
                            value={values[input.key]}
                            prefixCode={input.suffixKey ? values[input.suffixKey] : undefined}
                            alertMessage={alerts[input.key]}
                            alertMessageColor={color.mandatoryLabel}
                            suffix={input.suffix}
                            options={input.options ? options[input.options] : undefined}
                            type={type}
                            title={input.placeholder}
                            allowTypes={input.allowTypes}
                            maxNumber={input.maxNumber}
                          />
                        }
                      />
                    </Col>
                  )
                })}
              </Row>
            </NavigationBar>
          ) : null}
        </div>
      </AllPageCard>
    </div>
  )
}
