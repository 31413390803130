import { NavigationElementProps } from "components/NavigationBar/element"
import { AttachmentType, AttachmentTypeInfo } from "types/attachment"
import { RegistrationInfo } from "types/registration"

export const INFO_STEP_1: (keyof RegistrationInfo)[] = [
  "firstName",
  "lastName",
  "email",
  "playerImage",
  "birthDate",
  "countryId",
  "secondaryCountryId",
  "telephone",
  "telephonePrefix",
  "gender",
  "emailConfirmation",
  "address",
  "city",
  "countryOfResidenceId",
  "regionOfResidenceId",
  "postalCode",
  "emptyValue",
  "height",
  "weight",
  "hasNationalId",
  "nationalIdNumber",
  "nationalIdExpirationDate",
  "nationalIdFiles",
  "hasPassportId",
  "passportId",
  "passportExpirationDate",
  "passportFiles",
]

export const MANDATORY_STEP_1 = (values: RegistrationInfo): (keyof RegistrationInfo)[] => {
  return [
    "firstName",
    "lastName",
    "birthDate",
    "playerImage",
    "countryId",
    "telephone",
    "telephonePrefix",
    "gender",
    "email",
    "emailConfirmation",
    "address",
    "city",
    "countryOfResidenceId",
    "postalCode",
    "height",
    "weight",
    "hasNationalId",
    "nationalIdNumber",
    "nationalIdExpirationDate",
    "nationalIdFiles",
    "hasPassportId",
    "passportId",
    "passportExpirationDate",
    "passportFiles",
  ]
}

export const INFO_STEP_2: (keyof RegistrationInfo)[] = [
  "foot",
  "role",
  "videoLink",
  "hasPlayedInYouthTeam",
  "youthTeam",
  "youthTeamOptions",
  "yearsSpentInYouthTeams",
  "hasPlayedInProfessionalTeam",
  "professionalTeam",
  "yearsSpentInProfessionalTeams",
  "shortSize",
  "displayName",
  "tshirtSize",
]

export const MANDATORY_STEP_2 = (values: RegistrationInfo): (keyof RegistrationInfo)[] => {
  return ["tshirtSize", "shortSize", "displayName", "foot", "role", "videoLink"]
}

export const INFO_STEP_3: (keyof RegistrationInfo)[] = [
  "hasWorkingPermit",
  "hasResidencePermit",
  "isConditionAccepted",
  "isPrivacyPolicyAccepted",
  "isAdult",
  "isAvailableToPlay",
  "socialSecurityNumber",
  "hasMedicalCertificate",
  "medicalCertificateFiles",
]

export const MANDATORY_STEP_3 = (values: RegistrationInfo): (keyof RegistrationInfo)[] => {
  return [
    "hasWorkingPermit",
    "hasResidencePermit",
    "isConditionAccepted",
    "isPrivacyPolicyAccepted",
    "isAdult",
    "isAvailableToPlay",
    "hasMedicalCertificate",
    "medicalCertificateFiles",
    "socialSecurityNumber",
  ]
}

export const INFO_STEP_4: (keyof RegistrationInfo)[] = [
  "hasNationalId",
  "nationalIdNumber",
  "nationalIdExpirationDate",
  "isAvailableToPlay",
  //"nationalIdFiles",
  /*"hasCurpId",
  "curpId",
  "curpExpirationDate",
  "curpFiles",*/
  "hasPassportId",
  "passportId",
  "passportExpirationDate",
  "passportFiles",
]

export const MANDATORY_STEP_4 = (values: RegistrationInfo): (keyof RegistrationInfo)[] => {
  return [
    "nationalIdNumber",
    "nationalIdExpirationDate",
    "hasPassportId",
    "passportId",
    "passportExpirationDate",
    "passportFiles",
  ]
}

export const INFO_STEPS = {
  1: INFO_STEP_1,
  2: INFO_STEP_2,
  3: INFO_STEP_3,
}

export const MANDATORY_STEPS = {
  1: MANDATORY_STEP_1,
  2: MANDATORY_STEP_2,
  3: MANDATORY_STEP_3,
}

export const KL_AMERICAS_DRAFT_ID = 2
export const QL_AMERICAS_DRAFT_ID = 2

export const QL_AMERICAS_INFO_STEPS = {
  1: INFO_STEP_1,
  2: INFO_STEP_2,
  3: INFO_STEP_3,
}

export const KL_AMERICAS_INFO_STEPS = {
  1: INFO_STEP_1,
  2: INFO_STEP_2,
  3: INFO_STEP_3,
  //4: INFO_STEP_4,
}

export const KL_AMERICAS_MANDATORY_STEPS = {
  1: MANDATORY_STEP_1,
  2: MANDATORY_STEP_2,
  3: MANDATORY_STEP_3,
  //4: MANDATORY_STEP_4,
}

export const QL_AMERICAS_MANDATORY_STEPS = {
  1: MANDATORY_STEP_1,
  2: MANDATORY_STEP_2,
  3: MANDATORY_STEP_3,
}

export const PAGES: NavigationElementProps[] = [
  {
    step: 1,
    title: "REGISTRATION_STEP_1",
    status: "disabled",
  },
  {
    step: 2,
    title: "REGISTRATION_STEP_2",
    status: "disabled",
  },
  {
    step: 3,
    title: "REGISTRATION_STEP_3",
    status: "disabled",
  },
]

export const KL_AMERICAS_PAGES: NavigationElementProps[] = [
  {
    step: 1,
    title: "PERSONAL_INFORMATION_STEP",
    status: "active",
  },
  {
    step: 2,
    title: "SPORT_INFORMATION_STEP",
    status: "disabled",
  },
  {
    step: 3,
    title: "OTHER_INFORMATION_STEP",
    status: "disabled",
  },
  // {
  //   step: 4,
  //   title: "SPORT_INFORMATION_STEP",
  //   status: "disabled",
  // },
]

export const QL_AMERICAS_PAGES: NavigationElementProps[] = [
  {
    step: 1,
    title: "REGISTRATION_STEP_1",
    status: "active",
  },
  {
    step: 2,
    title: "REGISTRATION_STEP_2",
    status: "disabled",
  },
  {
    step: 3,
    title: "REGISTRATION_STEP_3",
    status: "disabled",
  },
]

export const API_PATH = "https://api.apply.kingsleague.pro"

export const ATTACHMENT_TYPES_OBJECT: {
  [key in AttachmentType]?: AttachmentTypeInfo
} = {
  pdf: {
    viewFunction: true,
    extension: ".pdf",
    color: "#8C2D2D",
    name: "PDF",
    uploadName: "SELECT_PDF",
    preview: "pdf",
  },
  image: {
    viewFunction: true,
    extension: ".png",
    color: "#D9B121",
    name: "IMG",
    preview: "image",
  },
}

export const LANGUAGES = [
  {
    id: "en",
    name: "English",
  },
  {
    id: "es",
    name: "Español",
  },
  // {
  //   id: "pt",
  //   name: "Português",
  // },
  // {
  //   id: "de",
  //   name: "Deutsch",
  // },
  // {
  //   id: "fr",
  //   name: "Français",
  // },
  // {
  //   id: "jp",
  //   name: "日本語",
  // },
  {
    id: "it",
    name: "Italiano",
  },
  // {
  //   id: "tr",
  //   name: "Türkçe",
  // },
]
