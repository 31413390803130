import { CountryDTO } from "types/generic"

export const COUNTRIES: CountryDTO[] = [
  {
    id: 1,
    name: "Italy",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/it.png",
    isoCode: "it",
    prefix: "+39",
  },
  {
    id: 129,
    name: "Afghanistan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/af.png",
    isoCode: "af",
    prefix: "+93",
  },
  {
    id: 5,
    name: "Albania",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/al.png",
    isoCode: "al",
    prefix: "+355",
  },
  {
    id: 47,
    name: "Algeria",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/dz.png",
    isoCode: "dz",
    prefix: "+213",
  },
  {
    id: 220,
    name: "American Samoa",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/as.png",
    isoCode: "as",
    prefix: "+1-684",
  },
  {
    id: 127,
    name: "Andorra",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ad.png",
    isoCode: "ad",
    prefix: "+376",
  },
  {
    id: 64,
    name: "Angola",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ao.png",
    isoCode: "ao",
    prefix: "+244",
  },
  {
    id: 210,
    name: "Anguilla",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ai.png",
    isoCode: "ai",
    prefix: "+1-264",
  },
  {
    id: 101,
    name: "Antigua and Barbuda",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ag.png",
    isoCode: "ag",
    prefix: "+1-268",
  },
  {
    id: 20,
    name: "Argentina",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ar.png",
    isoCode: "ar",
    prefix: "+54",
  },
  {
    id: 68,
    name: "Armenia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/am.png",
    isoCode: "am",
    prefix: "+374",
  },
  {
    id: 128,
    name: "Aruba",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/aw.png",
    isoCode: "aw",
    prefix: "+297",
  },
  {
    id: 90,
    name: "Australia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/au.png",
    isoCode: "au",
    prefix: "+61",
  },
  {
    id: 73,
    name: "Austria",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/at.png",
    isoCode: "at",
    prefix: "+43",
  },
  {
    id: 126,
    name: "Azerbaijan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/az.png",
    isoCode: "az",
    prefix: "+994",
  },
  {
    id: 130,
    name: "Bahamas",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bs.png",
    isoCode: "bs",
    prefix: "+1-242",
  },
  {
    id: 182,
    name: "Bahrain",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bh.png",
    isoCode: "bh",
    prefix: "+973",
  },
  {
    id: 196,
    name: "Bangladesh",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bd.png",
    isoCode: "bd",
    prefix: "+880",
  },
  {
    id: 131,
    name: "Barbados",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bb.png",
    isoCode: "bb",
    prefix: "+1-246",
  },
  {
    id: 75,
    name: "Belarus",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/by.png",
    isoCode: "by",
    prefix: "+375",
  },
  {
    id: 34,
    name: "Belgium",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/be.png",
    isoCode: "be",
    prefix: "+32",
  },
  {
    id: 211,
    name: "Belize",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bz.png",
    isoCode: "bz",
    prefix: "+501",
  },
  {
    id: 117,
    name: "Benin",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bj.png",
    isoCode: "bj",
    prefix: "+229",
  },
  {
    id: 159,
    name: "Bermuda",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bm.png",
    isoCode: "bm",
    prefix: "+1-441",
  },
  {
    id: 63,
    name: "Bolivia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bo.png",
    isoCode: "bo",
    prefix: "+591",
  },
  {
    id: 30,
    name: "Bosnia-Herzegovina",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ba.png",
    isoCode: "ba",
    prefix: "+387",
  },
  {
    id: 233,
    name: "Botswana",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bw.png",
    isoCode: "bw",
    prefix: "+267",
  },
  {
    id: 229,
    name: "Bouvet Island",
    image: null,
    isoCode: "bv",
    prefix: null,
  },
  {
    id: 17,
    name: "Brazil",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/br.png",
    isoCode: "br",
    prefix: "+55",
  },
  {
    id: 212,
    name: "British Virgin Islands",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 230,
    name: "Brunei",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bn.png",
    isoCode: "bn",
    prefix: "+673",
  },
  {
    id: 76,
    name: "Bulgaria",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bg.png",
    isoCode: "bg",
    prefix: "+359",
  },
  {
    id: 70,
    name: "Burkina Faso",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bf.png",
    isoCode: "bf",
    prefix: "+226",
  },
  {
    id: 122,
    name: "Burundi",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bi.png",
    isoCode: "bi",
    prefix: "+257",
  },
  {
    id: 227,
    name: "Cambodia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/kh.png",
    isoCode: "kh",
    prefix: "+855",
  },
  {
    id: 9,
    name: "Cameroon",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cm.png",
    isoCode: "cm",
    prefix: "+237",
  },
  {
    id: 38,
    name: "Canada",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ca.png",
    isoCode: "ca",
    prefix: "+1",
  },
  {
    id: 79,
    name: "Cape Verde",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cv.png",
    isoCode: "cv",
    prefix: "+238",
  },
  {
    id: 213,
    name: "Cayman Islands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ky.png",
    isoCode: "ky",
    prefix: "+1-345",
  },
  {
    id: 148,
    name: "Central African Republic",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cf.png",
    isoCode: "cf",
    prefix: "+236",
  },
  {
    id: 157,
    name: "Chad",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/td.png",
    isoCode: "td",
    prefix: "+235",
  },
  {
    id: 59,
    name: "Chile",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cl.png",
    isoCode: "cl",
    prefix: "+56",
  },
  {
    id: 152,
    name: "China",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cn.png",
    isoCode: "cn",
    prefix: "+86",
  },
  {
    id: 195,
    name: "Chinese Taipei",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 240,
    name: "Cocos (Keeling) Islands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cc.png",
    isoCode: "cc",
    prefix: "+61",
  },
  {
    id: 43,
    name: "Colombia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/co.png",
    isoCode: "co",
    prefix: "+57",
  },
  {
    id: 155,
    name: "Comoros",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/km.png",
    isoCode: "km",
    prefix: "+269",
  },
  {
    id: 156,
    name: "Congo",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cg.png",
    isoCode: "cg",
    prefix: "+242",
  },
  {
    id: 44,
    name: "Congo DR",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cd.png",
    isoCode: "cd",
    prefix: "+243",
  },
  {
    id: 221,
    name: "Cook Islands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ck.png",
    isoCode: "ck",
    prefix: "+682",
  },
  {
    id: 107,
    name: "Costa Rica",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cr.png",
    isoCode: "cr",
    prefix: "+506",
  },
  {
    id: 3,
    name: "Côte d'Ivoire",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ci.png",
    isoCode: "ci",
    prefix: "+225",
  },
  {
    id: 15,
    name: "Croatia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/hr.png",
    isoCode: "hr",
    prefix: "+385",
  },
  {
    id: 81,
    name: "Cuba",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cu.png",
    isoCode: "cu",
    prefix: "+53",
  },
  {
    id: 149,
    name: "Curacao",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cw.png",
    isoCode: "cw",
    prefix: "+599",
  },
  {
    id: 80,
    name: "Cyprus",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cy.png",
    isoCode: "cy",
    prefix: "+357",
  },
  {
    id: 19,
    name: "Czech Republic",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/cz.png",
    isoCode: "cz",
    prefix: "+420",
  },
  {
    id: 56,
    name: "Denmark",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/dk.png",
    isoCode: "dk",
    prefix: "+45",
  },
  {
    id: 232,
    name: "Djibouti",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/dj.png",
    isoCode: "dj",
    prefix: "+253",
  },
  {
    id: 179,
    name: "Dominica",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/dm.png",
    isoCode: "dm",
    prefix: "+1-767",
  },
  {
    id: 103,
    name: "Dominican Republic",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/do.png",
    isoCode: "do",
    prefix: "+1-809,1-829,1-849",
  },
  {
    id: 37,
    name: "Ecuador",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ec.png",
    isoCode: "ec",
    prefix: "+593",
  },
  {
    id: 104,
    name: "Egypt",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/eg.png",
    isoCode: "eg",
    prefix: "+20",
  },
  {
    id: 27,
    name: "England",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gb-eng.png",
    isoCode: "en",
    prefix: "+44",
  },
  {
    id: 183,
    name: "El Salvador",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sv.png",
    isoCode: "sv",
    prefix: "+503",
  },
  {
    id: 58,
    name: "Equatorial Guinea",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gq.png",
    isoCode: "gq",
    prefix: "+240",
  },
  {
    id: 178,
    name: "Eritrea",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/er.png",
    isoCode: "er",
    prefix: "+291",
  },
  {
    id: 13,
    name: "España",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/es.png",
    isoCode: "es",
    prefix: "+34",
  },
  {
    id: 26,
    name: "Estonia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ee.png",
    isoCode: "ee",
    prefix: "+372",
  },
  {
    id: 171,
    name: "Eswatini",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sz.png",
    isoCode: "sz",
    prefix: "+268",
  },
  {
    id: 97,
    name: "Ethiopia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/et.png",
    isoCode: "et",
    prefix: "+251",
  },
  {
    id: 226,
    name: "Falkland Islands (Islas Malvinas)",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 145,
    name: "Faroe Islands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/fo.png",
    isoCode: "fo",
    prefix: "+298",
  },
  {
    id: 167,
    name: "Fiji",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/fj.png",
    isoCode: "fj",
    prefix: "+679",
  },
  {
    id: 71,
    name: "Finland",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/fi.png",
    isoCode: "fi",
    prefix: "+358",
  },
  {
    id: 10,
    name: "France",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/fr.png",
    isoCode: "fr",
    prefix: "+33",
  },
  {
    id: 147,
    name: "French Guiana",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gf.png",
    isoCode: "gf",
    prefix: "+594",
  },
  {
    id: 237,
    name: "French Polynesia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/pf.png",
    isoCode: "pf",
    prefix: "+689",
  },
  {
    id: 125,
    name: "Gabon",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ga.png",
    isoCode: "ga",
    prefix: "+241",
  },
  {
    id: 6,
    name: "Gambia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gm.png",
    isoCode: "gm",
    prefix: "+220",
  },
  {
    id: 102,
    name: "Georgia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ge.png",
    isoCode: "ge",
    prefix: "+995",
  },
  {
    id: 25,
    name: "Germany",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/de.png",
    isoCode: "de",
    prefix: "+49",
  },
  {
    id: 7,
    name: "Ghana",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gh.png",
    isoCode: "gh",
    prefix: "+233",
  },
  {
    id: 66,
    name: "Giappone",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/jp.png",
    isoCode: "jp",
    prefix: "+81",
  },
  {
    id: 134,
    name: "Gibraltar",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gi.png",
    isoCode: "gi",
    prefix: "+350",
  },
  {
    id: 36,
    name: "Greece",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gr.png",
    isoCode: "gr",
    prefix: "+30",
  },
  {
    id: 160,
    name: "Grenada",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gd.png",
    isoCode: "gd",
    prefix: "+1-473",
  },
  {
    id: 61,
    name: "Guadeloupe",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gp.png",
    isoCode: "gp",
    prefix: "+590",
  },
  {
    id: 118,
    name: "Guam",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gu.png",
    isoCode: "gu",
    prefix: "+1-671",
  },
  {
    id: 200,
    name: "Guatemala",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gt.png",
    isoCode: "gt",
    prefix: "+502",
  },
  {
    id: 238,
    name: "Guernsey",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gg.png",
    isoCode: "gg",
    prefix: "+44",
  },
  {
    id: 60,
    name: "Guinea",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gn.png",
    isoCode: "gn",
    prefix: "+224",
  },
  {
    id: 62,
    name: "Guinea-Bissau",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gw.png",
    isoCode: "gw",
    prefix: "+245",
  },
  {
    id: 133,
    name: "Guyana",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gy.png",
    isoCode: "gy",
    prefix: "+592",
  },
  {
    id: 132,
    name: "Haiti",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ht.png",
    isoCode: "ht",
    prefix: "+509",
  },
  {
    id: 99,
    name: "Honduras",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/hn.png",
    isoCode: "hn",
    prefix: "+504",
  },
  {
    id: 217,
    name: "Hong Kong",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/hk.png",
    isoCode: "hk",
    prefix: "+852",
  },
  {
    id: 77,
    name: "Hungary",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/hu.png",
    isoCode: "hu",
    prefix: "+36",
  },
  {
    id: 65,
    name: "Iceland",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/is.png",
    isoCode: "is",
    prefix: "+354",
  },
  {
    id: 114,
    name: "India",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/in.png",
    isoCode: "in",
    prefix: "+91",
  },
  {
    id: 51,
    name: "Indonesia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/id.png",
    isoCode: "id",
    prefix: "+62",
  },
  {
    id: 106,
    name: "Iran",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ir.png",
    isoCode: "ir",
    prefix: "+98",
  },
  {
    id: 105,
    name: "Iraq",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/iq.png",
    isoCode: "iq",
    prefix: "+964",
  },
  {
    id: 115,
    name: "Ireland",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ie.png",
    isoCode: "ie",
    prefix: "+353",
  },
  {
    id: 139,
    name: "Isle of Man",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/im.png",
    isoCode: "im",
    prefix: "+44",
  },
  {
    id: 78,
    name: "Israel",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/il.png",
    isoCode: "il",
    prefix: "+972",
  },
  {
    id: 28,
    name: "Jamaica",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/jm.png",
    isoCode: "jm",
    prefix: "+1-876",
  },
  {
    id: 197,
    name: "Jersey",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/je.png",
    isoCode: "je",
    prefix: "+44",
  },
  {
    id: 175,
    name: "Jordan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/jo.png",
    isoCode: "jo",
    prefix: "+962",
  },
  {
    id: 224,
    name: "Jugoslawien (SFR)",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 119,
    name: "Kazakhstan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/kz.png",
    isoCode: "kz",
    prefix: "+7",
  },
  {
    id: 135,
    name: "Kenya",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ke.png",
    isoCode: "ke",
    prefix: "+254",
  },
  {
    id: 241,
    name: "Kiribati",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ki.png",
    isoCode: "ki",
    prefix: "+686",
  },
  {
    id: 74,
    name: "Korea DPR",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/kp.png",
    isoCode: "kp",
    prefix: "+850",
  },
  {
    id: 111,
    name: "Korea South",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/kr.png",
    isoCode: "kr",
    prefix: "+82",
  },
  {
    id: 94,
    name: "Korea, North",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 23,
    name: "Kosovo",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/xk.png",
    isoCode: "xk",
    prefix: null,
  },
  {
    id: 201,
    name: "Kuwait",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/kw.png",
    isoCode: "kw",
    prefix: "+965",
  },
  {
    id: 187,
    name: "Kyrgyzstan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/kg.png",
    isoCode: "kg",
    prefix: "+996",
  },
  {
    id: 202,
    name: "Laos",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 100,
    name: "Latvia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/lv.png",
    isoCode: "lv",
    prefix: "+371",
  },
  {
    id: 50,
    name: "Lebanon",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/lb.png",
    isoCode: "lb",
    prefix: "+961",
  },
  {
    id: 234,
    name: "Lesotho",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ls.png",
    isoCode: "ls",
    prefix: "+266",
  },
  {
    id: 153,
    name: "Liberia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/lr.png",
    isoCode: "lr",
    prefix: "+231",
  },
  {
    id: 82,
    name: "Libya",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ly.png",
    isoCode: "ly",
    prefix: "+218",
  },
  {
    id: 83,
    name: "Liechtenstein",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/li.png",
    isoCode: "li",
    prefix: "+423",
  },
  {
    id: 14,
    name: "Lithuania",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/lt.png",
    isoCode: "lt",
    prefix: "+370",
  },
  {
    id: 67,
    name: "Luxembourg",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/lu.png",
    isoCode: "lu",
    prefix: "+352",
  },
  {
    id: 228,
    name: "Macau",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 21,
    name: "Macedonia FYR",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mk.png",
    isoCode: "mk",
    prefix: "+389",
  },
  {
    id: 91,
    name: "Madagascar",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mg.png",
    isoCode: "mg",
    prefix: "+261",
  },
  {
    id: 112,
    name: "Malawi",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mw.png",
    isoCode: "mw",
    prefix: "+265",
  },
  {
    id: 180,
    name: "Malaysia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/my.png",
    isoCode: "my",
    prefix: "+60",
  },
  {
    id: 218,
    name: "Maldives",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mv.png",
    isoCode: "mv",
    prefix: "+960",
  },
  {
    id: 49,
    name: "Mali",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ml.png",
    isoCode: "ml",
    prefix: "+223",
  },
  {
    id: 108,
    name: "Malta",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mt.png",
    isoCode: "mt",
    prefix: "+356",
  },
  {
    id: 53,
    name: "Martinique",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mq.png",
    isoCode: "mq",
    prefix: "+596",
  },
  {
    id: 163,
    name: "Mauritania",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mr.png",
    isoCode: "mr",
    prefix: "+222",
  },
  {
    id: 162,
    name: "Mauritius",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mu.png",
    isoCode: "mu",
    prefix: "+230",
  },
  {
    id: 123,
    name: "Mayotte",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/yt.png",
    isoCode: "yt",
    prefix: "+262",
  },
  {
    id: 57,
    name: "Mexico",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mx.png",
    isoCode: "mx",
    prefix: "+52",
  },
  {
    id: 69,
    name: "Moldova",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/md.png",
    isoCode: "md",
    prefix: "+373",
  },
  {
    id: 84,
    name: "Monaco",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mc.png",
    isoCode: "mc",
    prefix: "+377",
  },
  {
    id: 191,
    name: "Mongolia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mn.png",
    isoCode: "mn",
    prefix: "+976",
  },
  {
    id: 72,
    name: "Montenegro",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/me.png",
    isoCode: "me",
    prefix: "+382",
  },
  {
    id: 109,
    name: "Montserrat",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ms.png",
    isoCode: "ms",
    prefix: "+1-664",
  },
  {
    id: 46,
    name: "Morocco",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ma.png",
    isoCode: "ma",
    prefix: "+212",
  },
  {
    id: 136,
    name: "Mozambique",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mz.png",
    isoCode: "mz",
    prefix: "+258",
  },
  {
    id: 137,
    name: "Namibia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/na.png",
    isoCode: "na",
    prefix: "+264",
  },
  {
    id: 231,
    name: "Nepal",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/np.png",
    isoCode: "np",
    prefix: "+977",
  },
  {
    id: 11,
    name: "Netherlands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/nl.png",
    isoCode: "nl",
    prefix: "+31",
  },
  {
    id: 184,
    name: "Netherlands Antilles",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 170,
    name: "New Caledonia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/nc.png",
    isoCode: "nc",
    prefix: "+687",
  },
  {
    id: 93,
    name: "New Zealand",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/nz.png",
    isoCode: "nz",
    prefix: "+64",
  },
  {
    id: 92,
    name: "Nicaragua",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ni.png",
    isoCode: "ni",
    prefix: "+505",
  },
  {
    id: 189,
    name: "Niger",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ne.png",
    isoCode: "ne",
    prefix: "+227",
  },
  {
    id: 35,
    name: "Nigeria",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ng.png",
    isoCode: "ng",
    prefix: "+234",
  },
  {
    id: 86,
    name: "North Macedonia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mk.png",
    isoCode: "mk",
    prefix: null,
  },
  {
    id: 113,
    name: "Northern Ireland",
    image: "https://s3.eu-central-2.wasabisys.com/kingsleague-cdn.kama.football/account/production/flags/gb-nir.png",
    isoCode: null,
    prefix: null,
  },
  {
    id: 225,
    name: "Northern Mariana Islands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mp.png",
    isoCode: "mp",
    prefix: "+1-670",
  },
  {
    id: 55,
    name: "Norway",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/bv.png",
    isoCode: "bv",
    prefix: "+47",
  },
  {
    id: 188,
    name: "Oman",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/om.png",
    isoCode: "om",
    prefix: "+968",
  },
  {
    id: 140,
    name: "Pakistan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/pk.png",
    isoCode: "pk",
    prefix: "+92",
  },
  {
    id: 85,
    name: "Palestinian Territory",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 169,
    name: "Panama",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/pa.png",
    isoCode: "pa",
    prefix: "+507",
  },
  {
    id: 193,
    name: "Papua New Guinea",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/pg.png",
    isoCode: "pg",
    prefix: "+675",
  },
  {
    id: 24,
    name: "Paraguay",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/py.png",
    isoCode: "py",
    prefix: "+595",
  },
  {
    id: 45,
    name: "Peru",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/pe.png",
    isoCode: "pe",
    prefix: "+51",
  },
  {
    id: 146,
    name: "Philippines",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ph.png",
    isoCode: "ph",
    prefix: "+63",
  },
  {
    id: 40,
    name: "Poland",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/pl.png",
    isoCode: "pl",
    prefix: "+48",
  },
  {
    id: 33,
    name: "Portugal",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/pt.png",
    isoCode: "pt",
    prefix: "+351",
  },
  {
    id: 151,
    name: "Puerto Rico",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/pr.png",
    isoCode: "pr",
    prefix: "+1",
  },
  {
    id: 173,
    name: "Qatar",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/qa.png",
    isoCode: "qa",
    prefix: "+974",
  },
  {
    id: 110,
    name: "Reunion",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/re.png",
    isoCode: "re",
    prefix: "+262",
  },
  {
    id: 18,
    name: "Romania",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ro.png",
    isoCode: "ro",
    prefix: "+40",
  },
  {
    id: 87,
    name: "Russia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ru.png",
    isoCode: "ru",
    prefix: "+7",
  },
  {
    id: 154,
    name: "Rwanda",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/rw.png",
    isoCode: "rw",
    prefix: "+250",
  },
  {
    id: 165,
    name: "Saint Kitts and Nevis",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/kn.png",
    isoCode: "kn",
    prefix: "+1-869",
  },
  {
    id: 166,
    name: "Saint Lucia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/lc.png",
    isoCode: "lc",
    prefix: "+1-758",
  },
  {
    id: 121,
    name: "Saint Martin",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/mf.png",
    isoCode: "mf",
    prefix: "+590",
  },
  {
    id: 198,
    name: "Saint Vincent and the Grenadines",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/vc.png",
    isoCode: "vc",
    prefix: "+1-784",
  },
  {
    id: 216,
    name: "Samoa",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ws.png",
    isoCode: "ws",
    prefix: "+685",
  },
  {
    id: 88,
    name: "San Marino",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sm.png",
    isoCode: "sm",
    prefix: "+378",
  },
  {
    id: 164,
    name: "Sao Tome and Principe",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/st.png",
    isoCode: "st",
    prefix: "+239",
  },
  {
    id: 194,
    name: "Saudi Arabia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sa.png",
    isoCode: "sa",
    prefix: "+966",
  },
  {
    id: 54,
    name: "Scotland",
    image: "https://s3.eu-central-2.wasabisys.com/kingsleague-cdn.kama.football/account/production/flags/gb-sct.png",
    isoCode: "sco",
    prefix: null,
  },
  {
    id: 8,
    name: "Senegal",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sn.png",
    isoCode: "sn",
    prefix: "+221",
  },
  {
    id: 39,
    name: "Serbia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/rs.png",
    isoCode: "rs",
    prefix: "+381",
  },
  {
    id: 199,
    name: "Seychelles",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sc.png",
    isoCode: "sc",
    prefix: "+248",
  },
  {
    id: 95,
    name: "Sierra Leone",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sl.png",
    isoCode: "sl",
    prefix: "+232",
  },
  {
    id: 176,
    name: "Singapore",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sg.png",
    isoCode: "sg",
    prefix: "+65",
  },
  {
    id: 32,
    name: "Slovakia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sk.png",
    isoCode: "sk",
    prefix: "+421",
  },
  {
    id: 4,
    name: "Slovenia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/si.png",
    isoCode: "si",
    prefix: "+386",
  },
  {
    id: 235,
    name: "Solomon Islands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sb.png",
    isoCode: "sb",
    prefix: "+677",
  },
  {
    id: 116,
    name: "Somalia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/so.png",
    isoCode: "so",
    prefix: "+252",
  },
  {
    id: 98,
    name: "South Africa",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/za.png",
    isoCode: "za",
    prefix: "+27",
  },
  {
    id: 150,
    name: "South Sudan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ss.png",
    isoCode: "ss",
    prefix: "+211",
  },
  {
    id: 192,
    name: "Sri Lanka",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/lk.png",
    isoCode: "lk",
    prefix: "+94",
  },
  {
    id: 124,
    name: "Sudan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sd.png",
    isoCode: "sd",
    prefix: "+249",
  },
  {
    id: 12,
    name: "Suriname",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/sr.png",
    isoCode: "sr",
    prefix: "+597",
  },
  {
    id: 29,
    name: "Sweden",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/se.png",
    isoCode: "se",
    prefix: "+46",
  },
  {
    id: 22,
    name: "Switzerland",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ch.png",
    isoCode: "ch",
    prefix: "+41",
  },
  {
    id: 141,
    name: "Syria",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 190,
    name: "Tajikistan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tj.png",
    isoCode: "tj",
    prefix: "+992",
  },
  {
    id: 96,
    name: "Tanzania",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tz.png",
    isoCode: "tz",
    prefix: "+255",
  },
  {
    id: 168,
    name: "Thailand",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/th.png",
    isoCode: "th",
    prefix: "+66",
  },
  {
    id: 174,
    name: "Timor-Leste",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tl.png",
    isoCode: "tl",
    prefix: "+670",
  },
  {
    id: 143,
    name: "Togo",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tg.png",
    isoCode: "tg",
    prefix: "+228",
  },
  {
    id: 236,
    name: "Tonga",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/to.png",
    isoCode: "to",
    prefix: "+676",
  },
  {
    id: 144,
    name: "Trinidad and Tobago",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tt.png",
    isoCode: "tt",
    prefix: "+1-868",
  },
  {
    id: 31,
    name: "Tunisia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tn.png",
    isoCode: "tn",
    prefix: "+216",
  },
  {
    id: 48,
    name: "Turkey",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tr.png",
    isoCode: "tr",
    prefix: "+90",
  },
  {
    id: 219,
    name: "Turkish Republic of Northern Cyprus",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 185,
    name: "Turkmenistan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tm.png",
    isoCode: "tm",
    prefix: "+993",
  },
  {
    id: 215,
    name: "Turks and Caicos Islands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/tc.png",
    isoCode: "tc",
    prefix: "+1-649",
  },
  {
    id: 89,
    name: "Uganda",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ug.png",
    isoCode: "ug",
    prefix: "+256",
  },
  {
    id: 42,
    name: "Ukraine",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ua.png",
    isoCode: "ua",
    prefix: "+380",
  },
  {
    id: 138,
    name: "United Arab Emirates",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ae.png",
    isoCode: "ae",
    prefix: "+971",
  },
  {
    id: 142,
    name: "United Kingdom",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gb.png",
    isoCode: "gb",
    prefix: "+44",
  },
  {
    id: 52,
    name: "United States",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/us.png",
    isoCode: "us",
    prefix: "+1",
  },
  {
    id: 2,
    name: "Uruguay",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/uy.png",
    isoCode: "uy",
    prefix: "+598",
  },
  {
    id: 120,
    name: "Uzbekistan",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/uz.png",
    isoCode: "uz",
    prefix: "+998",
  },
  {
    id: 223,
    name: "Vanuatu",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/vu.png",
    isoCode: "vu",
    prefix: "+678",
  },
  {
    id: 16,
    name: "Venezuela",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ve.png",
    isoCode: "ve",
    prefix: "+58",
  },
  {
    id: 158,
    name: "Vietnam",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/vn.png",
    isoCode: "vn",
    prefix: "+84",
  },
  {
    id: 214,
    name: "Virgin Islands",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/vi.png",
    isoCode: "vi",
    prefix: "+1-340",
  },
  {
    id: 41,
    name: "Wales",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/gb-wls.png",
    isoCode: null,
    prefix: null,
  },
  {
    id: 186,
    name: "Wallis and Futuna",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/wf.png",
    isoCode: "wf",
    prefix: "+681",
  },
  {
    id: 239,
    name: "Western Sahara",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/eh.png",
    isoCode: "eh",
    prefix: "+212",
  },
  {
    id: 222,
    name: "World",
    image: null,
    isoCode: null,
    prefix: null,
  },
  {
    id: 177,
    name: "Yemen",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/ye.png",
    isoCode: "ye",
    prefix: "+967",
  },
  {
    id: 172,
    name: "Zambia",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/zm.png",
    isoCode: "zm",
    prefix: "+260",
  },
  {
    id: 161,
    name: "Zimbabwe",
    image: "https://kingsleague-cdn.kama.football/account/production/flags/zw.png",
    isoCode: "zw",
    prefix: "+263",
  },
]
