import React, { useEffect, useState } from "react"

import { ConfigProvider } from "antd"
import { I18nextProvider } from "react-i18next"
import AllPage from "./components/AllPage/Container"
import Countdown from "components/Countdown"

import { AllPageType } from "types/allPage"
import { Language } from "types/generic"

import i18n, { changeLanguage } from "languages/index"
import { getInfoFromHostname } from "utility"
import { dayjsSelection } from "utility/localeSelection"
import { LANGUAGES } from "utility/constants"

import "./App.scss"
import dayjs from "dayjs"

function App() {
  const defaultLanguage = "it" as Language
  const [type, setType] = useState<AllPageType>(getInfoFromHostname())
  const [language, setLanguage] = useState<Language>(defaultLanguage)
  const [fontFamily, setFontFamily] = useState<string>()

  useEffect(() => {
    let language = navigator.language
    const availableLanguages = LANGUAGES.map((language) => language.id)
    if (availableLanguages.indexOf(language) === -1) {
      language = defaultLanguage
    }
    onChangeLanguage(language as Language)
  }, [])

  useEffect(() => {
    switch (type) {
      case "kings-league-america":
        setFontFamily("Archivo-Light")
        break
    }
  }, [type])

  const onChangeLanguage = (language: Language) => {
    setLanguage(language)
    changeLanguage(language)
    dayjsSelection(language)
  }

  const DRAFT_START = dayjs("2024-05-26T20:50:00.000Z").valueOf()
  const DRAFT_END = dayjs("2024-07-24T22:00:00.000Z").valueOf()
  const NOW_IN_MS = dayjs().valueOf()

  const gap = DRAFT_START - NOW_IN_MS
  const dateTimeAfterThreeDays = NOW_IN_MS + gap

  const isClosed = NOW_IN_MS > DRAFT_END

  return (
    <ConfigProvider
      theme={
        fontFamily
          ? {
              token: {
                fontFamily: fontFamily,
              },
            }
          : undefined
      }
    >
      <I18nextProvider i18n={i18n}>
        <AllPage type={type} language={language} onChangeLanguage={onChangeLanguage}>
          <Countdown targetDate={dateTimeAfterThreeDays} type={type} isClosed={isClosed} />
        </AllPage>
      </I18nextProvider>
    </ConfigProvider>
  )
}

export default App
